<template>
  <div class="contact-details">
    <m-page-title
      title="Additional details"
      describe="Please fill in your additional details.<br />*All fields on this page are optional."
    >
    </m-page-title>
    <div class="content">
      <el-form
        :model="contactForm"
        ref="contactForm"
        label-position="top"
        size="medium"
        @submit.native.prevent
      >
        <el-form-item label="Vehicle registration number" class="fullName">
          <el-input v-model="contactForm.registrationNumber" />
        </el-form-item>
        <el-form-item
          label="Vehicle make/ model (e.g. Toyota Yaris)"
          class="fullName"
        >
          <el-input v-model="contactForm.bodyType" />
        </el-form-item>
        <el-form-item label="Vehicle colour" class="fullName">
          <el-input v-model="contactForm.color" />
        </el-form-item>
        <el-form-item label="Agent Email" class="fullName">
          <el-input v-model="contactForm.agentEmail" />
        </el-form-item>
        <el-form-item label="Agent Phone" class="fullName">
          <el-input
            @input="inputPhone"
            v-model="contactForm.agentPhone"
            maxlength="10"
          />
        </el-form-item>

        <el-form-item size="large">
          <el-button type="primary" @click="proceed">Proceed</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  components: {},
  data() {
    return {
      contactForm: {
        registrationNumber: "",
        bodyType: "",
        color: "",
        agentEmail: "",
        agentPhone: "",
      },
    };
  },
  computed: {},
  created() {
    this.$axios.get(this.$api.vehicle).then((res) => {
      if (res.code === "1000") this.contactForm = res.data.memberVehicle;
    });
    this.keyupSubmit();
  },
  destroyed() {
    document.onkeydown = () => {};
  },
  methods: {
    // enter事件
    keyupSubmit() {
      document.onkeydown = () => {
        const _key = window.event.keyCode;
        if (_key === 13) {
          this.proceed();
        }
      };
    },
    proceed() {
      this.$axios.post(this.$api.vehicle, this.contactForm).then((res) => {
        if (res.code === "1000") {
          this.$notify.success("Saved successfully");
          this.$router.push("/buildingAddress?type=signup");
        }
      });
    },
    // 手机号数字校验
    inputPhone(e) {
      const arr = e.match(/\d/g);
      if (!arr) this.contactForm.phone = "";
      else this.contactForm.phone = arr.join("");
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
